import React from "react"
import DownloadAppBtn from "../download-app-btn"
class GetStartedCta extends React.Component {
  render() {
    const { className } = this.props

    return (
      <div
        className={`galaxy-background blog-border-radious ${className ?? ""}`}
      >
        <div className="d-flex email-cta-container blog-font-family p-4 justify-content-center">
          <div className="pr-9 d-none d-lg-block">
            <img
              className="cta-email"
              src={"/img/astronaut.png"}
              loading="lazy"
              alt={"Astronaut"}
            />
          </div>
          <div
            id="content"
            className="d-flex flex-column justify-content-center"
          >
            <h4 className=" text-white m-0">
              Invest your money to its full potential
            </h4>
            <p className="text-big mb-4">
              When you invest you capital is at risk.
            </p>
            <DownloadAppBtn color="light" />
          </div>
        </div>
      </div>
    )
  }
}

export default GetStartedCta
