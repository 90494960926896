import React from "react"
import BlogPostPreviewVerticalCard from "./blog-post-preview-vertical-card"

class BlogMultiplePostPreview extends React.Component {
  render() {
    const { items, className, itemsPerRow, limit } = this.props

    // Each row has either 2 or 3 items on desktop
    const itemClass =
      itemsPerRow === 3
        ? "blog-multiple-post-preview-three-items"
        : "blog-multiple-post-preview-two-items"

    // Apply the limit if it's provided
    const displayedItems = limit ? items.slice(0, limit) : items

    return (
      <>
        {/* Desktop */}
        <div className="d-none d-lg-block">
          <div className={`d-flex flex-wrap p-0 ${className ?? ""}`}>
            {displayedItems.map((post, index) => (
              <BlogPostPreviewVerticalCard
                key={post.title + index}
                className={`${itemClass} mb-11`}
                item={post}
              />
            ))}
          </div>
        </div>

        {/* Mobile */}
        <div
          className={`d-lg-none d-flex flex-column flex-md-wrap f gap-6 p-0 ${className ??
            ""}`}
        >
          {displayedItems.map((post, index) => (
            <BlogPostPreviewVerticalCard
              key={post.title + index}
              className="blog-multiple-post-preview-mobile"
              item={post}
            />
          ))}
        </div>
      </>
    )
  }
}

export default BlogMultiplePostPreview
