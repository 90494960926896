import React from "react"
import BlogCategoryTag from "./blog-category-tag"

class BlogSearch extends React.Component {
  render() {
    const { className, categoryNames } = this.props

    return (
      <div className={`${className ?? ""}`}>
        <div className="d-flex align-items-center flex-wrap justify-content-center gap-3 px-5 space-bottom-1 mb-2">
          {categoryNames.map(categoryName => (
            <BlogCategoryTag name={categoryName} />
          ))}
        </div>
      </div>
    )
  }
}

export default BlogSearch

// To be added next version
// <div className="space-bottom-1 mb-2">
// <div className="d-flex p-0 align-items-center blog-search-container text-medium">
//   <div className="pl-3 mr-2">
//     <img
//       src="/svg/search.svg"
//       alt={"search icon"}
//       style={{ width: "32px", height: "32px" }}
//     />
//   </div>
//   {/* dektop button*/}
//   <input
//     className="fw-bold blog-search-input border-0 d-none d-md-block"
//     type="text"
//     placeholder="Search by title, keyword or whatever you like!"
//     // onChange={this._handleSearchTermChange}
//   ></input>
//   {/* mobile input */}
//   <input
//     className="fw-bold blog-search-input border-0 d-md-none"
//     type="text"
//     placeholder="Search"
//     // onChange={this._handleSearchTermChange}
//   ></input>
//   {/* desktop button */}
//   <button className="cta cta-button btn button-tall text-medium d-bloc btn-primary bg-light-blue d-none d-md-block">
//     Let's go
//   </button>
//   {/* Mobile button */}
//   <button className="cta cta-button btn text-medium d-bloc btn-primary bg-light-blue d-md-none">
//     Let's go
//   </button>
// </div>
// </div>
// <div className="d-flex align-items-center flex-wrap justify-content-center gap-3 px-5 space-bottom-1 mb-2">
// {categoryName.map(categoryName => (
//   <BlogCategoryTag name={categoryName} />
// ))}
// </div>
// </div>
