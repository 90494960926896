import React from "react"
import DownloadAppBtn from "../download-app-btn"

class BlogFooterCta extends React.Component {
  render() {
    const { className } = this.props
    return (
      <div
        className={`position-relative d-flex justify-content-center space-top-lg-4 ${className ??
          ""}`}
      >
        <div
          className="d-flex flex-column position-absolute b-0 w-100"
          style={{ maxHeight: "100%" }}
        >
          <img
            className="w-100 z-index-0"
            src={"/img/mini-background.png"}
            alt={"Mini Background Pattern"}
            loading="lazy"
          />
          <div
            className="bg-dark-blue w-100 space-top-6 space-top-lg-4 space-top-xl-3 space-top-xxl-0 mt-n2 z-index-0"
            style={{ paddingTop: "24rem" }}
          />
        </div>
        <img
          className="position-absolute b-0 z-index-0 d-none d-lg-block max-w-100"
          src={"/svg/starry-pattern-non-index.svg"}
          alt={"Stars Pattern"}
          loading="lazy"
        />
        <div className="container space-bottom-1 space-top-1 space-top-xl-3">
          <div className="row">
            <div className="col-lg-4 d-flex justify-content-center align-items-center">
              <img
                src={"/img/astronaut-flamingo.png"}
                alt={"Astronaut flamingo"}
                loading="lazy"
              />
            </div>
            <div className="col-lg-8 space-top-lg-0 space-top-1 d-flex flex-column justify-content-center align-items-md-center align-items-lg-start z-index-1 pl-lg-11">
              <h2 className="mb-2 text-white text-left text-md-center text-lg-left">
                Invest your money to its full potential
              </h2>
              <p className="text-big text-white space-bottom-1 text-left text-md-center text-lg-left">
                When you invest your capital is at risk.
              </p>
              <DownloadAppBtn color="light" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BlogFooterCta
