import React from "react"
import BlogAuthorPreview from "./blog-author-preview"
import BlogCategoryTag from "./blog-category-tag"
import { Link } from "gatsby"
class BlogPostPreviewVerticalCard extends React.Component {
  #navigateToArticle = blogPostItem => {
    window.location.href = `/${blogPostItem.category.name}${
      blogPostItem.insightsCategory?.name
        ? "/" + blogPostItem.insightsCategory.name
        : ""
    }/${blogPostItem.slug}`.toLocaleLowerCase()
  }
  render() {
    const { item, className } = this.props
    return (
      <div className={`d-flex flex-column blog-font-family ${className ?? ""}`}>
        <div
          className="blog-post-preview-image-container-vertical blog-border-radious d-flex align-items-center justify-content-center px-2 py-0 cursor-pointer"
          onClick={() => this.#navigateToArticle(item)}
        >
          <img
            className="blog-post-preview-image rounded"
            src={item.image.file.url}
          />
        </div>
        <div id="content" className="d-flex flex-column gap-3 p-3">
          <div className="d-flex align-items-center">
            <div
              id="tag and reading time"
              className="d-flex gap-2 align-items-center me-3"
            >
              <BlogCategoryTag name={item.category.name} />
              <span className="">{item.readingTime} read</span>
            </div>
          </div>
          <Link
            className="cursor-pointer no-underline-link"
            to={`/${item.category.name}${
              item.insightsCategory?.name
                ? "/" + item.insightsCategory.name
                : ""
            }/${item.slug}`.toLocaleLowerCase()}
          >
            <h5 className="fw-bolder blog-text-dark text-big text-up-to-three-lines m-0">
              {item.title}
            </h5>
          </Link>
          <p className="m-0">{item.metaDescription}</p>
          <BlogAuthorPreview
            date={item.date}
            image={item.author.image.file.url}
            name={item.author.name}
          />
        </div>
      </div>
    )
  }
}

export default BlogPostPreviewVerticalCard
