import React from "react"
import slugify from "../../utils/slugify"
import { Link } from "gatsby"

class BlogCategoryTag extends React.Component {
  render() {
    const { name, className } = this.props

    return (
      <Link
        className="cursor-pointer no-underline-link"
        to={`/${slugify(name)}/`}
      >
        <span
          className={`fw-bold text-light-blue blog-category-tag ${className ??
            ""}`}
        >
          {name}
        </span>
      </Link>
    )
  }
}

export default BlogCategoryTag
