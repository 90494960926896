import React from "react"
import slugify from "../../utils/slugify"
import { formatDateToLongFormWithoutComma } from "../../utils/date"
import { Link } from "gatsby"

class BlogAuthorPreview extends React.Component {
  render() {
    const { name, image, date, className } = this.props

    return (
      <div className={`d-flex gap-4 align-items-center ${className ?? ""}`}>
        <div className="author-avatar-container">
          <Link className="cursor-pointer " to={`/${slugify(name)}/`}>
            <img src={image} className="author-avatar-image" />
          </Link>
        </div>
        <div className="">
          <Link
            className="cursor-pointer no-underline-link"
            to={`/${slugify(name)}/`}
          >
            <span className="text-medium fw-bolder d-block blog-text-dark">
              {name}
            </span>
          </Link>

          <span className="text-medium">
            {formatDateToLongFormWithoutComma(date)}
          </span>
        </div>
      </div>
    )
  }
}

export default BlogAuthorPreview
